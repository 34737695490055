import React, { useEffect, useState } from "react";

import "./Profile.css"
import {profiles} from "./ProfileData.js"

const Profile = ({person}) => {

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt){
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  return (
    <div className="Profile">
      <div className="ProfileImage">
        <img src={profiles[person].image}/>
      </div>
      <div className="ProfileCopy">
        <div className="ProfileName">
          {toTitleCase(profiles[person].name)}
        </div>
        <div className="ProfileBio">
          {profiles[person].bio}
        </div>
      </div>
    </div>
  );
};

export default Profile;