import React, { useState, useRef, useEffect } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import './Navigation.css';

import {profiles} from "./Pages/ProfileData.js"

function Navigation() {
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);

  console.log(window.location.pathname.split("/").at(-1) == "")
  // console.log((window.location.pathname.split("/")[-1]) == "/")
  // console.log((window.location.pathname.split("/")[-1]))

  const [nav, setNav] = useState([]);

  const handleMenuClick = () => {
    setShowMenu(!showMenu);
  };

  const handleClickOutside = (event) => {

  };

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function(txt){
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}
  
  useEffect(() => {
    var navArray = [<a className='item' href="/" onClick={handleMenuClick} current={("/" == window.location.pathname).toString()}>Home</a>]
    for (var key in profiles) {
      navArray.push(<a className='item' href={"/" + key} onClick={handleMenuClick} current={window.location.pathname.includes(key).toString()}>{toTitleCase(profiles[key].name)}</a>)
    }
    setNav(navArray)
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div>
    <nav className="navbar">
      <div className="navbar-brand"><a href="/">Sikkings in Unity</a></div>
      <div className="navbar-items">
        <a className="navbar-item" href="/" clicked={(window.location.pathname.split("/").at(-1) == "").toString()}>Home</a>
        <a className="navbar-item" href="/SUE" clicked={window.location.pathname.includes("SUE").toString()}>Sue</a>
        <a className="navbar-item" href="/ARTHUR-SR" clicked={window.location.pathname.includes("ARTHUR-SR").toString()}>Arthur</a>
        <a className="navbar-item" href="/ARTHUR-JR" clicked={window.location.pathname.includes("ARTHUR-JR").toString()}>Arthur Jr.</a>
        <a className="navbar-item" href="/ROBERT" clicked={window.location.pathname.includes("ROBERT").toString()}>Robert</a>
        <a className="navbar-item" href="/CHARLES" clicked={window.location.pathname.includes("CHARLES").toString()}>Charles</a>
        <a className="navbar-item" href="/JOY" clicked={window.location.pathname.includes("JOY").toString()}>Joy</a>
        <a className="navbar-item" href="/JAMES" clicked={window.location.pathname.includes("JAMES").toString()}>James</a>
        <a className="navbar-item" href="/MORE" clicked={window.location.pathname.includes("MORE").toString()}>Further Generations</a>
      </div>
    </nav>
    <nav className="hamburger-navbar">
      <div className="hamburger-navbar-brand"><a href="/">Sikkings in Unity</a></div>
      <div className="hamburger-menu-icon" onClick={handleMenuClick}>
        {showMenu ? <FaTimes /> : <FaBars />}
      </div>
      {showMenu && (
        <div className="hamburger-menu-items">
          {nav}
          <a href="/MORE" onClick={handleMenuClick}>
            Further Generations
          </a>
          {/* <a href="/SHOP" onClick={handleMenuClick}>
            🛒 Shop
          </a>
          <a href="/FAMILY-TREE" onClick={handleMenuClick}>
            🌳 Sikking Family Tree
          </a>
          <a href="/FAMILY-ALBUM" onClick={handleMenuClick}>
            📸 Sikking Family Album
          </a> */}
        </div>
      )}
    </nav>
    </div>
  );
}

export default Navigation;
