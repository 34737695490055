import React, { useEffect, useState } from "react";

import "./Relatives.css"
import {people} from "./PeopleData.js"

const Relatives = () => {

  return (
    <div className="Relatives">
        {people.map((person) =>
            <div>
            <h3>{person.section}</h3>
            <div className="Person">
            <b>{person.name}</b>
            <br></br>
            {person.descriptor}
            <br></br>
            <b><a style={{color:"inherit"}} href={person.link} target="_blank">{person.link }</a></b>
            </div>
            </div>
        )}
    </div>
  );
};

export default Relatives;