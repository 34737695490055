import React, { Component } from 'react';

import './LandingPage.css'

import welcome from '../Images/onlybelieve.png'
import religions from '../Images/religions.png'

export class LandingPage extends React.Component {
    render() {
      return (
        <div className='LandingPage'>
            <div>
            {/* <div className='LandingImage'>
              <img src={welcome}/>
              <center><b>"Only Believe"</b> coming soon...</center>
            </div> */}
            <div className='Inline'>
              <div className='LandingHeader'>
                {/* <div><img style={{height:"40px", width:"auto"}} src={religions}/></div> */}
                  Welcome to Sikkings in Unity
                {/* <div><img className="UnderImage" src={religions}/></div> */}
              </div>
              <div className='LandingBody'>
                  Throughout the 1900s two generations of the SIKKING family became prominent spiritual leaders, training at Unity School of Christianity at <a href='https://www.unityvillage.org/' target="_blank">Unity Village in Missouri, USA</a>. 
                  <br></br>
                  <br></br>
                  This website celebrates their contributions to twentieth century thinking, documents their work, and offers access to their books.
                  <br></br>
                  <br></br>
                  In addition to these family members, further generations have gained profile as entrepreneurs, scientists, doctors, actors, writers, and musicians. Information about them is available <a href='/MORE'>here</a>.
              </div>
              <div className='LandingQuote'>
                <i>"As we move into the great God plan to fulfill our physical, mental, and spiritual unfoldment, we will find that the power that completes is love."</i><br></br> - Sue Sikking
              </div>
            </div>
            <div className='LandingImage'>
              <img className="Book" src={welcome}/>
              <center>
              <b>"Only Believe"</b> coming soon...at $9.99<br></br>
              <b><a style={{color:"inherit"}} href="mailto:sikkingsinunity@gmail.com" target="_blank">EMAIL</a></b> us to pre-order your copy at $8.99 
              </center>
            </div>
            </div>
        </div>
      )
    }
  }