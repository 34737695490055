import logo from './logo.svg';
import './App.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Navigation from './Navigation';
import { LandingPage } from './Pages/LandingPage';
import Profile from './Pages/Profile';
import { FamilyTree } from './Pages/FamilyTree';
import { Album } from './Pages/Album';

import Relatives from './Pages/Relatives';

function App() {
  return (
    <div className="App">
      <Navigation/>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage/>}/>
          <Route path="/SUE" element={<Profile person="SUE"/>}/>
          <Route path="/ARTHUR-SR" element={<Profile person="ARTHUR-SR"/>}/>
          <Route path="/ARTHUR-JR" element={<Profile person="ARTHUR-JR"/>}/>
          <Route path="/ROBERT" element={<Profile person="ROBERT"/>}/>
          <Route path="/CHARLES" element={<Profile person="CHARLES"/>}/>
          <Route path="/JOY" element={<Profile person="JOY"/>}/>
          <Route path="/JAMES" element={<Profile person="JAMES"/>}/>
          <Route path="/FAMILY-TREE" element={<FamilyTree/>}/>
          <Route path="/FAMILY-ALBUM" element={<Album/>}/>
          <Route path="/MORE" element={<Relatives/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;