import React from 'react';

import PhotoAlbum from "react-photo-album";

const photos = [
    { src: require("../Images/fam.jpeg"), width: 800, height: 600 },
    { src: require("../Images/sue.png"),width: 800, height: 600 },
];

export class Album extends React.Component {
    render() {
        return (
            <p>
            </p>
        );
    }
}