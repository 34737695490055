export const people = [
    {
      "name": "Athena Dean Holtz",
      "descriptor": "author and motivational speaker",
      "link": "https://athenadeanholtz.com/",
      "category": "GRANDCHILDREN",
      "section": "GRANDCHILDREN"
    },
    {
      "name": "Emily Sikking",
      "descriptor": "obstetrician and gynaecologist",
      "link": "https://www.facebook.com/emilysikkingmd.obgyn/",
      "category": "GRANDCHILDREN"
    },
    {
      "name": "Arthur Lee Land",
      "descriptor": "musical artist",
      "link": "https://www.arthurleeland.com",
      "category": "GRANDCHILDREN"
    },
    {
      "name": "Anne Sikking",
      "descriptor": "writer and poet",
      "link": "https://www.annesikking.com",
      "category": "GRANDCHILDREN"
    },
    {
      "name": "Andrew Sikking",
      "descriptor": "actor and producer",
      "link": "https://instagram.com/andrewsikking",
      "category": "GRANDCHILDREN"
    },
    {
      "name": "Anamyn Turowski",
      "descriptor": "writer and editor",
      "link": "https://www.anamyn.com/",
      "category": "GRANDCHILDREN"
    },
    {
      "name": "Sue Sikking Frietas",
      "descriptor": "Coming soon...",
      "link": "Coming soon...",
      "category": "GRANDCHILDREN"
    },
    {
      "name": "Ian Allen",
      "descriptor": "web developer",
      "link": "https://www.instagram.com/ianwallen/",
      "category": "GREAT GRANDCHILDREN",
      "section": "GREAT GRANDCHILDREN"
    },
    {
      "name": "Robert Blue Cloud Jackson",
      "descriptor": "bassist and graphic designer",
      "link": "https://youtu.be/vybQkqobUrY",
      "category": "GREAT GRANDCHILDREN"
    },
    {
      "name": "Oliver Allen",
      "descriptor": "physicist and car engineer",
      "link": "https://www.instagram.com/oliverallen14/",
      "category": "GREAT GRANDCHILDREN"
    },
    {
      "name": "Alfred Millett-Sikking",
      "descriptor": "physicist and engineer",
      "link": "https://scholar.google.com/citations?user=S7yHencAAAAJ&hl=en",
      "category": "GREAT GRANDCHILDREN"
    },
    {
      "name": "Leah Amber Jackson-Blake",
      "descriptor": "EU water catchment and protection scientist",
      "link": "https://www.niva.no/en/Employees/leah-amber-jackson-blake",
      "category": "GREAT GRANDCHILDREN"
    },
    {
      "name": "Florence Millett-Sikking de Valladares",
      "descriptor": "classical singer",
      "link": "https://youtu.be/_40x5SnhI74",
      "category": "GREAT GRANDCHILDREN"
    },
    {
      "name": "Ada MIllett-Sikking Valladares",
      "descriptor": "vocalist and musician",
      "link": "https://youtu.be/CCw8Ju_S-nQ",
      "category": "GREAT GREAT GRANDCHILDREN",
      "section": "GREAT GREAT GRANDCHILDREN"
    },
    {
      "name": "Eddie Allen",
      "descriptor": "actor",
      "link": "https://www.eddieallen.com",
      "category": "OUTLAWS",
      "section": "OUTLAWS"
    }
  ];
  