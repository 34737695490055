export const profiles = {
    "SUE": {
        "name": "SUE SIKKING",
        "image": require("../Images/sue.png"),
        "bio": <p>
            Born Myn Sue Paxton, March 15, 1899, in Middleton, Missouri, Sue married Arthur Leland Sikking in 1920, a few days past her 21st birthday. When their second son Bob was born in1922, they travelled with him and their firstborn Art Junior to California. Once settled, three more children, Tom, Joy, and Jim were born in quick succession. Shortly after the Depression, in order to save money, she and some friends drove to Detroit to take delivery of a new car. On the way they were involved in a head on collision. Sue broke both of her knees, her jaw alone was fractured in more than forty places. As she fought for her life, she made a promise to God – if He allowed her to return to her family she vowed to serve Him for as long as she lived.
            <br></br><br></br>
            So began a lifetime of service to the Unity Ministry. Dr. Sue, as she was fondly known, trained in the teachings of Unity, broadly following the teachings of Charles and Myrtle Fillmore, and then later interpreting them to a still wider audience.
            <br></br><br></br>
            Her religious work included establishing Unity-by-the-Sea in Santa Monica California in 1946, which she stewarded for more than forty years. During this time she also authored six seminal works, all of which are now being relaunched and are available through us <a style={{color:"inherit"}} href="mailto:sikkingsinunity@gmail.com" target="_blank">here</a>
        </p>
    },
    "ARTHUR-SR" : {
        "name": "ARTHUR LELAND SIKKING SR",
        "image": require("../Images/arthur.png"),
        "bio": <p>Before becoming ordained at Unity, Arthur Leland Sikking's career had been varied, and largely entrepreneurial – pioneering frozen orange juice, helping build Encyclopaedia Britannica, dipping into property acquisition. In the American Air Service he served over France during World War I as a bomber, in the days when the hatch opened and bombs were dropped by hand. He was an amateur boxer, briefly the US Air Service middleweight champion. Known universally as Pop, once retired, he often assisted Sue on the platform at Unity-by-the-Sea. In the early days, when the congregation's meeting places were more temporary, he was also found setting out and gathering in the chairs.</p>
    },
    "ARTHUR-JR" : {
        "name": "ARTHUR LELAND SIKKING JR",
        "image": require("../Images/arthurjr.jpeg"),
        "bio": <p>After World War II service in the US Navy, Art, or Junior as his parents called him, Sue and Pop's eldest child worked for Encyclopaedia Britannica in Chicago, then the principal encyclopaedia in the world. Before the Internet libraries were the primary source of information, making an encyclopaedia a common home reference tool. Often extending to 20+ hardback, leather-bound, volumes, orders were taken by sales teams who sold door to door. Beginning as just such a lowly salesman Art ultimately became the company president. This was the heyday of paper-based information, the sweet spot after World War II when the middle-classes were enjoying growing prosperity, and before the digital revolution. His work took him into high society and all over the world, often meeting policy makers and world leaders.</p>
    },
    "ROBERT" : {
        "name": "ROBERT PAXTON SIKKING",
        "image": require("../Images/robert.jpg"),
        "bio": <p>Although born in Decatur, Illinois, Bob grew up in California and joined the US Army paratroop infantry 101st Airborne at the outbreak of World War II. His participation behind enemy lines in Normandy and Arnhem, the prolonged winter campaign in Bastogne, were acknowledged by two Purple Hearts, the Bronze Star, and a Presidential Citation. Attending the liberation of the concentration camp at Dachau marked him, and once discharged he used the GI Bill to pay for his Unity ministerial training. In 1956 he moved his family to the UK for ten years to pioneer the Unity ministry in England. He contributed widely to religious publications and his two books are now being relaunched and are available through us here</p>
    },
    "CHARLES" : {
        "name": "CHARLES THOMAS SIKKING",
        "image": require("../Images/tom.jpg"),
        "bio": <p>Tom was Sue and Pop's third and middle child, and the first to be born in California. He joined the Navy straight from High School toward the end of World War II. Returning to a career in the Los Angeles Police Department, he went on to work in Labor Law Enforcement. After teaching within colleges in California and Washington he entered the Unity Ministry.
        <br></br><br></br>
        During his early ministry he worked as Prison Chaplain at Kansas State Penitentiary before gaining further experience in four churches throughout the USA, spending his final years as senior minister at the Unity Church in Jacksonville, Florida. Latterly he served on Unity's Board of Directors and Licensing and Ordination Committee.
         <br></br><br></br>
        It was his work with convicts that largely inspired his best-selling book, Creative Responsibility, which will shortly be available here.</p>
    },
    "JOY" : {
        "name": "JOY SIKKING TUROWSKI",
        "image": require("../Images/joy.png"),
        "bio": <p>Joy Darlene Sikking was born April 15, 1930 in Los Angeles, the only daughter, and the fourth of the five children born to Sue and Arthur Sikking. She was a member of the US Navy, served as a WAVE, and was stationed in Japan. She married Walter Turowski in 1958, and in 1959 their only child Anamyn was born.  In 1961 she began training as a Unity minister and was ordained in 1963. Joy worked alongside her mother Sue Sikking at Unity by the Sea until 1983 when she founded Unity West Church. In addition to her ministerial work, and her life as wife and mother, Joy was also an accomplished sculptor and a lover of animals. She died on December 8, 2013, survived by her daughter Anamyn Turowski, her grandsons Ian and Oliver, and their father Eddie Allen.</p>
    },
    "JAMES" : {
        "name": "JAMES BARRIE SIKKING",
        "image": require("../Images/james.jpg"),
        "bio": <p>James Barrie Sikking was born March 5, 1934. He is the youngest son of Sue and Arthur. He graduated from UCLA in 1959, though his professional film career began in 1955. He married Florine Caplan Sikking in 1962. They have two children, Andrew and Emily, and four grandchildren, Hugh, Maddy, Lola, and Gemma.
        Sikking starred in Hill Street Blues as Howard Hunter (1981-1987), Doogie Howser, M.D. as Dr. David Howser  and on the 1997 drama series Brooklyn South as Captain Stan Jonas. He also portrayed Geoffrey St. James on the NBC comedy Turnabout and voiced General Gordon on the short-lived 1998 cartoon series Invasion America. 
        His film work includes The Competition, Outland, Up the Creek, Star Trek III: The Search for Spock, Ordinary People, and Narrow Margin, as well as a crucial role, as a cynical hitman, in Point Blank. Sikking starred opposite Jim Carrey in the 1992 TV movie Doing Time on Maple Drive. He has made guest appearances on numerous TV series. 
         <br></br><br></br>
        Additionally, James has been a decades long supporter of Susan B. Komen Organization and the Cystic Fibrosis Foundation.</p>
    }
}